import AppContext from 'context/AppContext'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import Collapse from 'react-bootstrap/Collapse'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'

import getUserMappingAndDashboardList from '../use-cases/getUserMappingAndDashboardList'
import LoadingSpinner from 'components/common/LoadingSpinner'
import getQuickSightURL from '../use-cases/getQuickSightURL'
import UserLevelMap from 'utils/user-level-map'
import useElementNames from 'hooks/useElementNames'
import pluralize from 'pluralize'

const embeddingContext = await createEmbeddingContext()

const BusinessIntelligence = () => {
  const elementNames = useElementNames()
  const { repoFactory, selectedYear, theme } = useContext(AppContext)
  const userRole = JSON.parse(localStorage.getItem('roles'))
  const userAccess = UserLevelMap[userRole]
  const [open, setOpen] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [elementAccountList, setElementAccountList] = useState([])

  const [url, setUrl] = useState()
  const [dashboardList, setDashboardList] = useState([])
  const [element1List, setElement1List] = useState([])
  const [element2List, setElement2List] = useState([])
  const [element3List, setElement3List] = useState([])
  const [element4List, setElement4List] = useState([])
  const [accountList, setAccountList] = useState([])

  const [selectedElement1, setSelectedElement1] = useState([])
  const [selectedElement2, setSelectedElement2] = useState([])
  const [selectedElement3, setSelectedElement3] = useState([])
  const [selectedElement4, setSelectedElement4] = useState([])
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [selectedDashboard, setSelectedDashboard] = useState()
  const [embeddedDashboardExperience, setEmbeddedDashboardExperience] = useState()

  let embeddedDashboard

  useEffect(() => {
    setIsLoading(true)
    getUserMappingAndDashboardList(
      {
        token: localStorage.getItem('authToken'),
        year: selectedYear
      },
      {
        biRepo: repoFactory.biRepo(),
        observer: {
          errorReceivingData: () => {
            setIsLoading(false)
          },
          receiveData: ({ qsDashboardObj, elementAccountList }) => {
            setIsLoading(false)
            setElementAccountList(elementAccountList)
            setDashboardList(qsDashboardObj.dashboardList.map((item) => ({ value: item.value, label: item.name })))
          }
        }
      }
    )
  }, [repoFactory, selectedYear])

  useEffect(() => {
    let element1Array = elementAccountList.map((item) => item.element1)
    element1Array = [...new Set(element1Array)].map((value) => ({ value, label: value }))
    setElement1List(element1Array)
    setSelectedElement1(element1Array)

    let element2Array = elementAccountList.map((item) => item.element2)
    element2Array = [...new Set(element2Array)].map((value) => ({ value, label: value }))
    setSelectedElement2(element2Array)

    let element3Array = elementAccountList.map((item) => item.element3)
    element3Array = [...new Set(element3Array)].map((value) => ({ value, label: value }))
    setSelectedElement3(element3Array)

    let element4Array = elementAccountList.map((item) => item.element4)
    element4Array = [...new Set(element4Array)].map((value) => ({ value, label: value }))
    setSelectedElement4(element4Array)

    let accountArray = elementAccountList.map((item) => `${item.accountName} ${item.accountId}`)
    accountArray = [...new Set(accountArray)].map((value) => ({ value: value.split(' ').pop().trim(), label: value }))
    setSelectedAccounts(accountArray)
  }, [elementAccountList])

  useEffect(() => {
    let element2Array = elementAccountList
      .filter((item) => selectedElement1.map((item) => item.value).includes(item.element1))
      .map((item) => item.element2)

    element2Array = [...new Set(element2Array)].map((value) => ({ value, label: value }))

    setElement2List(element2Array)

    setSelectedElement2(selectedElement2.filter((item) => element2Array.map((item) => item.value).includes(item.value)))
  }, [selectedElement1])

  useEffect(() => {
    let element3Array = elementAccountList
      .filter((item) => selectedElement2.map((item) => item.value).includes(item.element2))
      .map((item) => item.element3)

    element3Array = [...new Set(element3Array)].map((value) => ({ value, label: value }))

    setElement3List(element3Array)
    setSelectedElement3(selectedElement3.filter((item) => element3Array.map((item) => item.value).includes(item.value)))
  }, [selectedElement2])

  useEffect(() => {
    let element4Array = elementAccountList
      .filter((item) => selectedElement3.map((item) => item.value).includes(item.element3))
      .map((item) => item.element4)

    element4Array = [...new Set(element4Array)].map((value) => ({ value, label: value }))

    setElement4List(element4Array)
    setSelectedElement4(selectedElement4.filter((item) => element4Array.map((item) => item.value).includes(item.value)))
  }, [selectedElement3])

  useEffect(() => {
    let accountArray = elementAccountList
      .filter((item) => selectedElement4.map((item) => item.value).includes(item.element4))
      .map((item) => `${item.accountName} ${item.accountId}`)

    accountArray = [...new Set(accountArray)].map((value) => ({ value: value.split(' ').pop().trim(), label: value }))
    setAccountList(accountArray)

    setSelectedAccounts(selectedAccounts.filter((item) => accountArray.map((item) => item.value).includes(item.value)))
  }, [selectedElement4])

  const getUrl = () => {
    if (selectedAccounts && selectedDashboard) {
      setOpen(false)
      setIsLoading(true)
      setUrl(null)
      const accountIdList = selectedAccounts.map((item) => item.value).join()
      getQuickSightURL(
        {
          accountIdList,
          dashboardId: selectedDashboard.value
        },
        {
          biRepo: repoFactory.biRepo(),
          observer: {
            errorGettingUrl: (error) => {
              setIsLoading(false)
            },
            receiveQuickSightUrl: (response) => {
              setUrl(response.EmbedUrl)
              setIsLoading(false)
            }
          }
        }
      )
    }
  }

  useEffect(() => {
    if (url) {
      if (embeddedDashboardExperience) {
        document
          .getElementById('experience-container')
          .removeChild(document.getElementById('experience-container').lastChild)
        setEmbeddedDashboardExperience(null)
      }
      updateDashboard(url, theme)
    }
  }, [url])

  const updateDashboard = useCallback(
    async (url) => {
      try {
        const frameOptions = {
          url,
          container: '#experience-container',
          height: '800px',
          width: '100%',
          resizeHeightOnSizeChangedEvent: true,
          onChange: (changeEvent, metadata) => {
            switch (changeEvent.eventName) {
              case 'FRAME_MOUNTED': {
                console.log('Do something when the experience frame is mounted.')
                break
              }
              case 'FRAME_LOADED': {
                console.log('Do something when the experience frame is loaded.')

                break
              }
            }
          }
        }

        const contentOptions = {
          locale: 'en-US',
          toolbarOptions: {
            export: true,
            undoRedo: true,
            reset: true
          },
          attributionOptions: {
            overlayContent: false
          },
          onMessage: async (messageEvent, experienceMetadata) => {
            switch (messageEvent.eventName) {
              case 'CONTENT_LOADED': {
                console.log('All visuals are loaded. The title of the document:', messageEvent.message.title)
                setEmbeddedDashboardExperience(embeddedDashboard)
                break
              }
              case 'ERROR_OCCURRED': {
                console.log(
                  'Error occurred while rendering the experience. Error code:',
                  messageEvent.message.errorCode
                )
                setEmbeddedDashboardExperience(embeddedDashboard)
                break
              }
              case 'PARAMETERS_CHANGED': {
                console.log('Parameters changed. Changed parameters:', messageEvent.message.changedParameters)
                break
              }
              case 'SELECTED_SHEET_CHANGED': {
                console.log('Selected sheet changed. Selected sheet:', messageEvent.message.selectedSheet)
                break
              }
              case 'SIZE_CHANGED': {
                console.log('Size changed. New dimensions:', messageEvent.message)
                break
              }
              case 'MODAL_OPENED': {
                window.scrollTo({
                  top: 0 // iframe top position
                })
                break
              }
            }
          }
        }

        embeddedDashboard = await embeddingContext.embedDashboard(frameOptions, contentOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    [url, setEmbeddedDashboardExperience]
  )

  useEffect(() => {
    if (embeddedDashboardExperience) {
      let colorId =
        '{"DataColorPalette":{"Colors":["#00558C","#F9B218","#8A50B3","#7FD4B9","#F65D6A","#4263A6","#3D993D","#E673C9"],"MinMaxGradient":["#CCF0FF","#29A8DD"],"EmptyFillColor":"#F6F7F8"},"UIColorPalette":{"PrimaryForeground":"#030303","PrimaryBackground":"#F9FAFC","SecondaryForeground":"#F9FAFC","SecondaryBackground":"#A1CD64","Accent":"#A1CD64","AccentForeground":"#A1CD64"},"Typography":{"FontFamilies":[{"FontFamily":"Open Sans"}]}}'

      if (theme === 'dark') {
        colorId =
          '{"DataColorPalette":{"Colors":["#117A8B","#22D475","#8A50B3","#7FD4B9","#F65D6A","#4263A6","#3D993D","#E673C9"],"MinMaxGradient":["#CCF0FF","#29A8DD"],"EmptyFillColor":"#F6F7F8"},"UIColorPalette":{"PrimaryForeground":"#F9FAFC","PrimaryBackground":"#2A3439","SecondaryForeground":"#F9FAFC","SecondaryBackground":"#2A3439","Accent":"#FFFFFF","AccentForeground":"#A1CD64"},"Typography":{"FontFamilies":[{"FontFamily":"Open Sans"}]}}'
      }

      embeddedDashboardExperience
        .setThemeOverride(JSON.parse(colorId))
        .then((response) => {})
        .catch((error) => {
          console.error(error)
        })
    }
  }, [theme, embeddedDashboardExperience])

  return (
    <div className="business-inteligence">
      {isLoading && <LoadingSpinner />}
      <Card>
        <Card.Body>
          <div className="text-end">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setOpen(!open)
              }}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
          </div>
          <hr />

          <Collapse in={open}>
            <Row className="mb-3">
              <Col lg="3">
                <label>{pluralize(elementNames?.element1 || '')}</label>
                <Select
                  isMulti
                  value={selectedElement1}
                  options={element1List}
                  classNamePrefix="select"
                  onChange={(selected) => setSelectedElement1(selected)}
                />
              </Col>

              <Col lg="3">
                <label>{pluralize(elementNames?.element2 || '')}</label>
                <Select
                  isMulti
                  value={selectedElement2}
                  options={element2List}
                  classNamePrefix="select"
                  onChange={(selected) => setSelectedElement2(selected)}
                  isDisabled={!selectedElement1.length}
                />
              </Col>

              <Col lg="3">
                <label>{pluralize(elementNames?.element3 || '')}</label>
                <Select
                  isMulti
                  value={selectedElement3}
                  options={element3List}
                  classNamePrefix="select"
                  onChange={(selected) => setSelectedElement3(selected)}
                  isDisabled={!selectedElement2.length}
                />
              </Col>
              <Col lg="3">
                <label>{pluralize(elementNames?.element4 || '')}</label>
                <Select
                  isMulti
                  value={selectedElement4}
                  options={element4List}
                  classNamePrefix="select"
                  onChange={(selected) => setSelectedElement4(selected)}
                  isDisabled={!selectedElement3.length}
                />
              </Col>

              <Col lg="5">
                <label>Account</label>
                <Select
                  isMulti
                  value={selectedAccounts}
                  options={accountList}
                  classNamePrefix="select"
                  className="select-account"
                  onChange={(selected) => setSelectedAccounts(selected)}
                  isDisabled={!selectedElement4.length}
                />
              </Col>
            </Row>
          </Collapse>
          <Row>
            <label>Dashboard</label>
            <Col lg="3">
              <Select
                options={dashboardList}
                classNamePrefix="select"
                isDisabled={!selectedAccounts.length}
                onChange={(option) => setSelectedDashboard(option)}
              />
            </Col>
            <Col>
              <Button onClick={getUrl} disabled={!selectedAccounts.length || !selectedDashboard} className="mt-3">
                Load
              </Button>
            </Col>
          </Row>

          <hr />
          <Row>
            <Col>
              <div id="experience-container"></div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
export default BusinessIntelligence
